export const pageLinks = [
  {
    id: 1,
    text: "Home",
    url: "/",
  },
  {
    id: 3,
    text: "About me",
    url: "/about",
  },
  {
    id: 4,
    text: "Blog",
    url: "/blog/1",
  },
  {
    id: 5,
    text: "Contact me",
    url: "/contact/",
  },
];
